footer {
    background-color: #005099;
    color: #fff;
    display: block;
    text-align: center;
    min-height: 40px;

    .div-footer {
        padding: 16px 0;
        display: flex;
        align-items: center;

        .span-flex-1 {
            flex: 1;
        }

        a {
            text-decoration: none;
            color: #fff;
            margin-right: 5px;
        }

        .span-made-by {
            padding-left: 10px;
            text-align: left;
        }
    }
}
