header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999999;
    display: flex;

    height: 55px;
    background-color: #005099;

    .logo-fiskagroup {
        background-color: white;
        height: 100%;
        padding: 0 10px;
    }

    .header-triangle-code {
        display: inline-block;
        height: 0;
        width: 0;
        border-top: 55px solid transparent;
        border-left: 60px solid #fff;
    }

    .title-header {
        margin-left: 20px;
        font-size: 20px;
        color: white;
    }
}
