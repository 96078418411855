.container-converter {
    text-align: center;

    section {
        margin-top: 50px;
        padding-top: 50px;
        background-color: #f3f4f5;
        min-height: calc(100vh - 151px);

        .div-container-upload-file {
            width: 80%;
            margin: auto;

            .title-span-upload-file {
                font-size: 22px;
                font-weight: bold;
                color: #005099;
                display: block;
                text-align: left;
                text-decoration: underline;
            }

            .div-description-upload-file {
                text-align: left;
                margin-top: 20px;
                margin-bottom: 40px;

                .span-description-upload-file {
                    display: block;
                    margin-top: 10px;
                }
            }

            .custom-file-upload {
                border: 1px dotted #ccc;
                background-color: white;
                display: inline-block;
                padding: 25px 50px;
                cursor: pointer;
                font-size: 30px;
                transition: all 0.2s ease;

                &:hover {
                    color: #005099;
                    background-color: #eee;
                    border: 1px solid #005099;
                    transition: all 0.2s ease;
                }

                input[type="file"] {
                    display: none;
                }

                .fa-cloud-upload-alt {
                    margin-right: 10px;
                }
            }

            .div-file-upload-infos {
                margin-top: 50px;

                .file-preview {
                    margin: 30px 10px 0px 10px;

                    .file-name {
                        padding: 10px;
                        display: block;
                        border: 1px solid black;
                        width: fit-content;
                        margin: auto;
                        border-radius: 50px;
                        background-color: white;
                        cursor: pointer;
                        transition: all 0.2s ease;

                        .icon-trash {
                            margin-left: 10px;
                        }

                        &:hover {
                            color: #005099;
                            background-color: #eee;
                            border: 1px solid #005099;
                            transition: all 0.2s ease;
                        }
                    }
                }

                .div-file-infos {
                    text-align: left;
                    margin-top: 25px;

                    .span-file-infos {
                        color: #005099;
                        font-size: 18px;
                    }
                }

                .div-file-error {
                    text-align: left;
                    margin-top: 25px;

                    .span-file-error {
                        color: red;
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
            }
        }

        .div-generate-data {
            margin-top: 50px;
            display: flex;
            justify-content: space-evenly;

            .btn-download {
                border: none;
                padding: 12px 25px !important;
                border-radius: 30px;
                font-weight: 700;
                color: #fff !important;
                background: #ed5576;
                cursor: pointer;
                box-sizing: border-box;
                transition: all 0.2s ease;

                a {
                    color: white !important;
                    text-decoration: none !important;
                }

                &:hover {
                    transform: scale(1.05);
                    transition: all 0.2s ease;
                }
            }
        }

        .div-loading {
            padding-top: 50px;

            .div-container-loading {
                display: flex;
                align-items: flex-end;
                justify-content: center;

                .span-loading {
                    font-size: 18px;
                    color: #005099;
                }
            }

            .div-container-loading-spinner {
                position: relative;
                width: 200px;
                height: 200px;
                margin: auto;
                animation: cssDisplay 0s 4.1s forwards;
                visibility: hidden;

                .app-logo {
                    height: 50px;
                    pointer-events: none;
                    position: absolute;
                    z-index: 1;
                    top: 75px;
                    left: 60px;
                }

                .spinner-box {
                    width: 200px;
                    height: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;

                    .circle-border {
                        width: 100px;
                        height: 100px;
                        padding: 3px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 50%;
                        background: #005099;
                        background: linear-gradient(0deg, #f3f4f5 33%, #005099 100%);
                        animation: spin 0.8s linear 0s infinite;

                        .circle-core {
                            width: 100%;
                            height: 100%;
                            background-color: #f3f4f5;
                            border-radius: 50%;
                            position: relative;
                        }
                    }
                }

                @keyframes spin {
                    from {
                        transform: rotate(0);
                    }
                    to {
                        transform: rotate(359deg);
                    }
                }
            }

            .div-container-number-invoices-generated {
                position: relative;
                width: 100%;
                margin: auto;
                animation: cssDisplay 0s 4.1s forwards;
                visibility: hidden;
            }

            @keyframes cssDisplay {
                to {
                    visibility: visible;
                }
            }
        }
    }

    .typing-demo {
        width: 100%;
        animation: typing 4s steps(50);
        white-space: nowrap;
        overflow: hidden;
        font-family: monospace;
        font-size: 2em;
    }

    @keyframes typing {
        from {
            width: 0;
        }
    }

    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .div-container-loading .dot {
        width: 3px;
        height: 3px;
        background-color: #005099;
        display: inline-block;
        margin: 1px;
        margin-left: 3px;
        border-radius: 50%;
        vertical-align: text-bottom;
    }

    .div-container-loading .dot:first-child {
        animation-name: loading;
        animation-duration: 1.5s;
        animation-iteration-count: infinite;
    }

    .div-container-loading .dot:nth-child(2) {
        animation-name: loading;
        animation-duration: 1.5s;
        animation-delay: 0.1s;
        animation-iteration-count: infinite;
    }

    .div-container-loading .dot:last-child {
        animation-name: loading;
        animation-duration: 1.5s;
        animation-delay: 0.2s;
        animation-iteration-count: infinite;
    }

    @keyframes loading {
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

.progress {
    background-color: #d8d8d8;
    border-radius: 20px;
    position: relative;
    margin: 15px 0;
    height: 30px;
    max-width: 400px;
    margin: 10px auto;
}

.progress-done {
    background: linear-gradient(to left, #005099, #185892);
    box-shadow: 0 3px 3px -5px #005099, 0 2px 5px #005099;
    border-radius: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 0;
    transition: 0.2s ease 0.1s;
}

.container-select-address {
    margin-top: 40px;

    .span-description-select-address {
        display: block;
        text-align: left;
        margin-top: 20px;
        margin-bottom: 40px;
    }

    .container-choose-address {
        display: flex;
        justify-content: center;
    }

    .div-choose-address {
        border: 1px solid #005099;
        border-radius: 20px;
        display: block;
        margin: 15px;
        padding: 15px;
        min-width: 175px;
        width: fit-content;
        cursor: pointer;

        &:hover {
            border: 1px solid #005099;
            background-color: #005099ad;
            color: white;
        }

        span {
            display: block;
            padding: 4px;
        }
    }

    .div-choose-address-selected {
        border: 1px solid #ed5576;
        background-color: #ed5576;
        color: white;
    }
}

.italic {
    font-style: italic;
}
