.login-wrapper {
    min-height: calc(100vh - 51px);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f3f4f5;

    .login-container {
        width: 400px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0 auto;
        background-color: white;
        box-shadow: 0 1px 3px rgb(0 0 0 / 6%), 0 1px 2px rgb(0 0 0 / 12%);
        overflow: hidden;
        padding-top: 10px;
        padding-bottom: 10px;
        margin-top: 100px;
        border-radius: 20px;
        position: relative;

        .logo-fiskagroup {
            width: 250px;
        }

        .title-login {
            font-size: 2em;
            padding: 30px 0 20px 0;
            color: #005099;
            font-weight: 500;
        }

        .description-login {
            opacity: 0.7;
            font-size: 18px;
            width: 90%;
            text-align: center;
            margin-bottom: 30px;
        }

        .div-input-login {
            width: 100%;
            position: relative;

            .label-input-login {
                font-size: 10pt;
                color: #005099;
                padding-bottom: 5px;
                white-space: nowrap;
            }

            .input-login {
                font-size: 13px;
                padding: 10px;
                background: #f5f6fa 0 0 no-repeat padding-box;
                border: 0.6px solid #d5d5d5;
                border-radius: 4px;
                max-height: 45px;
                width: 100%;
                margin-top: 5px;
                box-sizing: border-box;

                &:hover {
                    border: 1px solid #005099aa;
                }

                &:focus {
                    border: 1px solid #005099;
                    outline: none;
                }
            }

            &:first-child {
                margin-bottom: 20px;
            }
        }

        .span-error-login {
            position: absolute;
            font-size: 12px;
            color: red;
            margin-top: 10px;
        }

        .div-button-login {
            text-align: center;
            margin-top: 15px;

            .btn-login {
                width: 60%;
                background-color: #005099;
                color: #fff;
                font-weight: 700;
                padding: 12px 25px !important;
                border-radius: 30px;
                box-sizing: border-box;
                transition: all 0.2s ease;
                margin: 20px 0;
                border: none;
                cursor: pointer;

                &:hover,
                &:focus {
                    box-shadow: 0px 3px 20px 3px rgba(#005099, 0.3);
                    background-color: #005099;
                    transform: scale(1.05);
                    transition: all 0.2s ease;
                }
            }
        }
    }
}
